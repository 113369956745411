
import { getTypeFromSector, colorBySectorId } from "~/helpers/rent-level-helpers.js";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },

    omitRadius: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    colorBySectorId: colorBySectorId,
    getTypeFromSector: getTypeFromSector,
  },
};
